:root {
    --color_de_fondo: #FFFFFF;
    --color_de_fondo_grid: #d1d0d0;
}

.container {
    width: 60%;
    margin: 60px auto;
    background-color: var(--color_de_fondo);
    border-radius: 4px;
    padding: 40px 50px 40px 50px;
    -webkit-box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
    box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
}

.container--shippingInfoList {
    text-align: center;
}

.container--waybillsTable {
    width: 84%;
}

.container_login {
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 200px;
}

.containerGrid {
    background-color: var(--color_de_fondo_grid);
    padding: 0 35px 35px 35px;
    margin: 10px auto 5px auto;
    border-radius: 4px;
    -webkit-box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
    box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
}

.containerGrid--waybillsTable {
    
}

.containerboton {
    margin: 0px 515px 40px 0px;
}

.containerboton--paymentConfirmation {
    margin: 0px 515px 8px -97px;
}

.formulario {
    background-color: var(--color_de_fondo_grid);
    padding: 20px;
    border-radius: 4px;
    -webkit-box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
    box-shadow: -1px 4px 5px -4px rgba(0,0,0,0.75);
}

.formulario fieldset.fieldsetMain {
    border: none;
}

.formulario legend {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #FF4500;
    margin-left: 158px;
}

.formulario--shippingInsurance legend {
    margin-left: 180px;
}

.container--methodsOfPayment {
    text-align: center;
    width: 36%;    
}

.containerGrid--originOrDestination {
    padding-top: 35px;
    margin-top: -33px;
}

.containerGrid--paymentConfirmation {
    padding-top: 35px;
    margin-top: 10px;
}

.labelExcel {
    cursor : pointer;
    background-color : #009f2d;
    border : 2px solid #009f2d;
    color : #ffffff;
    padding : 6px 7px 2px 7px;
    border-radius : 8px;
}

.labelExcel:hover{
    background-color : #027723;
    color : #dcdcdc;
    border : 2px solid #027723;
}

.logInButton{
    display: flex;
    align-items: flex-start;
}

@media ( max-width : 479px ){
    .logInButton{
        display: flex;
        align-items: center;
    }
}